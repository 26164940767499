
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroService } from './services/hero.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,

  ],
  providers: [HeroService]
})
export class CoreModule { }
