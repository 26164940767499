import { tabsStructure } from '../../modules/main/components/tabs/tabs.component';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeroService {

public streemTabs$: Subject<tabsStructure> = new Subject<tabsStructure>();
// tslint:disable-next-line: new-parens

  constructor() {}

  // tslint:disable-next-line: typedef
  newTab(tab: tabsStructure) {
    this.streemTabs$.next(tab);
  }
}


