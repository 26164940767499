
<div class="login">
  <h2 class="login-header">Vaillant portal</h2>
  <form [formGroup]="authForm" class="login-container" (ngSubmit)="signIn()">
    <p [ngClass]="{ 'has-error': isSubmitted && formControls.login.errors }">
      <input type="email" placeholder="Login" formControlName="login">
    </p>
    <div *ngIf="isSubmitted && formControls.login.errors" class="help-block">
      <div *ngIf="formControls.login.errors.required">Email is required</div>
    </div>
    <p [ngClass]="{ 'has-error': isSubmitted && formControls.password.errors }">
      <input type="password" placeholder="Password" formControlName="password">
    </p>
    <div *ngIf="isSubmitted && formControls.password.errors" class="help-block">
      <div *ngIf="formControls.password.errors.required">Password is required</div>
    </div>
    <p>
      <button type="submit" [disabled]="authForm.invalid || authForm.disabled">
        Log in
      </button>

    </p>
  </form>
</div>
