import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {}

  authForm: FormGroup;
  isSubmitted = false;
  asub: Subscription;

  ngOnInit() {
    this.authForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.route.queryParams.subscribe((params: Params) => {
      if (params['registered']) {
        //теперь вы можете войти
      } else if (params['accessDenied']) {
        //для начала авторизируйтесь в системе
      }
    });
  }

  ngOnDestroy() {
    if (this.asub) {
      this.asub.unsubscribe();
    }
  }

  get formControls() {
    return this.authForm.controls;
  }

  signIn() {
    this.isSubmitted = true;
    this.authForm.disable();
    if (this.authForm.invalid) {
      return;
    }
    this.asub = this.authService.signIn(this.authForm.value).subscribe(
      () => this.router.navigate(['/']),
      (error) => {
        console.warn(error);
        this.authForm.enable();
      }
    );
    //  this.router.navigateByUrl('/');
  }
}
