import { Component } from '@angular/core';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vaillant';

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    const potencialToken = localStorage.getItem('auth-token');
    if (potencialToken !== null){
      this.auth.setToken(potencialToken);
    }
  }
}
